import './Wizard.scss';

import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import SiteStructure from './components/aem-structure/SiteStructure';
import AddHeaderFooter from './components/assembler-quickstart/AddHeaderFooter';
import Composition from './components/assembler-quickstart/Composition';
import Congratulations from './components/assembler-quickstart/Congratulations';
import Intro from './components/assembler-quickstart/Intro';
import MaintenanceAndErrorPage from './components/assembler-quickstart/MaintenanceAndErrorPage';
import MicrosoftTeamsNotificationSetup from './components/assembler-quickstart/MicrosoftTeamsNotificationSetup';
import Routing from './components/assembler-quickstart/Routing';
import { BlogPost } from './components/blog-post/BlogPost';
import ChangeDisplayedTile from './components/embed-dynamic-pages/ChangeDisplayedTile';
import CreateTilePage from './components/embed-dynamic-pages/CreateTilePage';
import EmbedCongratulations from './components/embed-dynamic-pages/EmbedCongratulations';
import EmbedIntro from './components/embed-dynamic-pages/EmbedIntro';
import MarkerComponent from './components/embed-dynamic-pages/MarkerComponent';
import JFrogInstructions from './components/JFrogInstructions';
import CreatePage from './components/new-page/CreatePage';
import ProductName from './components/new-page/ProductName';
import Summary from './components/new-page/Summary';
import DeployingAssembler from './components/provision-assembler/DeployingAssembler';
import FurtherCustomizations from './components/provision-assembler/FurtherCustomizations';
import IntakeRequest from './components/provision-assembler/IntakeRequest';
import ProvisioningAssembler from './components/provision-assembler/ProvisioningAssembler';
import { PublishInstructions } from './components/PublishInstructions';
import Migration from './components/release/Migration';
import Release from './components/release/Release';
import AppName from './components/tiles/AppName';
import CreateTileInstructions from './components/tiles/CreateTileInstructions';
import TransactionalPage from './components/tiles/TransactionalPage';
import Home from './Home';

const Wizard = () => {
  return (
    <ThemeProvider themeName={ThemeName.FORD_PRO} mode={Mode.LIGHT_MODE}>
      <div className="Wizard">
        <Router>
          <Routes>
            <Route path="dynamic-page">
              <Route index element={<TransactionalPage />} />
              <Route path="app-name" element={<AppName />} />
              <Route path="instructions" element={<CreateTileInstructions />} />
            </Route>
            <Route path="new-page">
              <Route index element={<ProductName />} />
              <Route path="create" element={<CreatePage />} />
              <Route path="summary" element={<Summary />} />
            </Route>
            <Route path="assembler-quickstart">
              <Route index element={<Intro />} />
              <Route path="composition" element={<Composition />} />
              <Route path="add-header-footer" element={<AddHeaderFooter />} />
              <Route path="routing" element={<Routing />} />
              <Route path="maintenance-and-error-page" element={<MaintenanceAndErrorPage />} />
              <Route path="congratulations" element={<Congratulations />} />
              <Route path="microsoft-teams-notification-setup" element={<MicrosoftTeamsNotificationSetup />} />
            </Route>
            <Route path="embed-dynamic-pages">
              <Route index element={<EmbedIntro />} />
              <Route path="create-page-tile" element={<CreateTilePage />} />
              <Route path="marker-component" element={<MarkerComponent />} />
              <Route path="change-displayed-tile" element={<ChangeDisplayedTile />} />
              <Route path="embed-dynamic-page-congratulations" element={<EmbedCongratulations />} />
            </Route>
            <Route path="provisioning-assembler">
              <Route index element={<ProvisioningAssembler />} />
              <Route path="intake-request" element={<IntakeRequest />} />
              <Route path="deploying-assembler" element={<DeployingAssembler />} />
              <Route path="further-customizations" element={<FurtherCustomizations />} />
            </Route>
            <Route path="aem-structure">
              <Route index element={<SiteStructure />} />
            </Route>
            <Route path="blog-posts/:post?" element={<BlogPost />} />
            <Route path="releases/:version?" element={<Release />} />
            <Route path="releases/:version/migration" element={<Migration />} />
            <Route path={`${process.env.AEM_CONTENT_BASE}.html`} element={<Home />} />
            <Route path={`${process.env.AEM_CONTENT_BASE}`}>
              <Route path="new-page.html" element={<ProductName />} />
              <Route path="new-page/create-a-page.html" element={<CreatePage />} />
              <Route path="new-page/summary.html" element={<Summary />} />
              <Route path="dynamic-page.html" element={<TransactionalPage />} />
              <Route path="dynamic-page/app-name.html" element={<AppName />} />
              <Route path="dynamic-page/instructions.html" element={<CreateTileInstructions />} />
              <Route path="assembler-quickstart.html" element={<Intro />} />
              <Route path="assembler-quickstart/composition.html" element={<Composition />} />
              <Route path="assembler-quickstart/add-header-footer.html" element={<AddHeaderFooter />} />
              <Route path="assembler-quickstart/routing.html" element={<Routing />} />
              <Route path="assembler-quickstart/maintenance-and-error-page.html" element={<MaintenanceAndErrorPage />} />
              <Route path="assembler-quickstart/microsoft-teams-notification-setup.html" element={<MicrosoftTeamsNotificationSetup />} />
              <Route path="assembler-quickstart/congratulations.html" element={<Congratulations />} />
              <Route path="embed-dynamic-pages.html" element={<EmbedIntro />} />
              <Route path="embed-dynamic-pages/create-page-tile.html" element={<CreateTilePage />} />
              <Route path="embed-dynamic-pages/marker-component.html" element={<MarkerComponent />} />
              <Route path="embed-dynamic-pages/change-displayed-tile.html" element={<ChangeDisplayedTile />} />
              <Route path="embed-dynamic-pages/embed-dynamic-page-congratulations.html" element={<EmbedCongratulations />} />
              <Route path="provisioning-assembler.html" element={<ProvisioningAssembler />} />
              <Route path="provisioning-assembler/intake-request.html" element={<IntakeRequest />} />
              <Route path="provisioning-assembler/deploying-assembler.html" element={<DeployingAssembler />} />
              <Route path="provisioning-assembler/further-customizations.html" element={<FurtherCustomizations />} />
              <Route path="aem-structure.html" element={<SiteStructure />} />
              <Route path="components/jfrog-instructions.html" element={<JFrogInstructions />} />
              <Route path="components/publish-instructionS.html" element={<PublishInstructions />} />
              <Route path="releases/*" element={<Release />} />
              <Route path="blog-posts/*" element={<BlogPost />} />
              <Route path="releases/:version/migration.html" element={<Migration />} />
            </Route>
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default Wizard;
