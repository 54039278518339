import { AEMPage, EditableFlexContainer } from '@fcamna/aem-library';
import { AEMPageModel } from '@fcamna/aem-library/src/utils/page.types';
import { List, ListItem } from '@fcamna/react-library';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import withAnimatedNavigation from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageHeader } from '../Page';

const Title = styled.h1`
  font-family: 'Ford F-1', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  align-self: start;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;
  padding-bottom: 100px;
`;

const LeftContent = styled.div`
  width: 300px;
  margin-top: 100px;
  .listTitle {
    font-size: large;
    font-weight: bold;
    margin: 0;
  }
  .selectedListItem {
    font-weight: bolder;
  }
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  max-height: 300px;
  padding: 0 20px 0 0;
`;

export function BlogPost() {
  const [model, setModel] = useState<AEMPageModel | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  const post = params.post;

  const basePath = `${process.env.AEM_CONTENT_BASE}/blog-posts`;

  const allPosts = useMemo(() => {
    return Object.keys(model?.[':children'] || {}).map((path) => ({
      pageName: path.replace(`${basePath}/`, ''),
      title: model[':children'][path].title,
      isSelected: path.replace(`${basePath}/`, '') == post
    }));
  }, [model, post]);

  const currentPost = useMemo(() => {
    return allPosts.find(({ isSelected }) => isSelected) || allPosts[0];
  }, [allPosts]);

  const path = model ? `${basePath}/${currentPost.pageName}` : basePath;

  const onClickPost = (v: string) => () => navigate(`/blog-posts/${v}`);

  return (
    <AEMPage pagePath={path} aemHost={getAEMHost()} onPageModelLoad={(model) => setModel(model)} model={model?.[':children']?.[path]}>
      {model && (
        <Page>
          <PageHeader></PageHeader>
          <PageContent style={{ flexDirection: 'row' }}>
            <LeftContent>
              <p className="listTitle">Blog Posts: </p>
              <ScrollableContent>
                <List type="bullet">
                  {allPosts.map(({ pageName, title, isSelected }) => (
                    <ListItem key={pageName} onClick={onClickPost(pageName)}>
                      <div className={isSelected ? 'selectedListItem' : ''}>{title}</div>
                    </ListItem>
                  ))}
                </List>
              </ScrollableContent>
            </LeftContent>
            <RightContent>
              <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                <Title>{currentPost?.title} </Title>
              </div>
              <EditableFlexContainer name="what-included" key={path} />
            </RightContent>
          </PageContent>
        </Page>
      )}
    </AEMPage>
  );
}

export default withAnimatedNavigation(BlogPost);
