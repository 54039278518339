import './Header.scss';

import { Button } from '@fcamna/react-library';
import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import styled from 'styled-components';

import QuantumLogo from '../../assets/QuantumLogo-White_with_eyes.png';

const HeaderLink = styled(Button)`
  .Header__link.Button {
    color: white;

    &:hover {
      color: #066fef;
    }
  }
`;

const LogoButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

const Header = () => {
  return (
    <ThemeProvider themeName={ThemeName.FORD_CREDIT} mode={Mode.DARK_MODE}>
      <div className="Header">
        <LogoButton onClick={() => (window.location.href = '/')}>
          <img src={QuantumLogo} alt={'quantum-logo'} style={{ height: '60px', width: '100px' }} />
        </LogoButton>
        <div>
          <Button variant="text" className="Header__link" onClick={() => (window.location.href = '/releases')}>
            <span>Releases</span>
          </Button>
          <Button variant="text" className="Header__link" onClick={() => (window.location.href = '/blog-posts')}>
            <span>Blog Posts</span>
          </Button>
          <HeaderLink
            onClick={() => window.open('https://www.eesewiki.ford.com/pages/viewpage.action?pageId=1495184987')}
            className="Header__link"
            variant="text">
            <span>Docs</span>
          </HeaderLink>
          <Button
            variant="text"
            className="Header__link"
            onClick={() => window.open('https://github.com/ford-innersource/universal-component-library')}>
            <span>Component Library</span>
          </Button>
          <Button
            variant="text"
            className="Header__link"
            onClick={() => window.open('https://ford-credit.atlassian.net/servicedesk/customer/portal/1007/group/1016/create/1051')}>
            <span>Content Request</span>
          </Button>
          <Button
            variant="text"
            className="Header__link"
            onClick={() => {
              window.location.href = '/#access-container';
            }}>
            <span>Access Request</span>
          </Button>
          <Button
            variant="text"
            className="Header__link"
            onClick={() => window.open('https://forms.office.com/r/tTXxMHEnjZ?origin=lprLink')}>
            <span>Feedback</span>
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Header;
